<template>
  <div class="content_9">
    <div class="left">
      <div class="bg"></div>
      <div class="content-1">实践案例：商业体——华盛DAC</div>
      <div class="content-2">
        贡献值系统被全方面应用在华盛科技控股股份有限公司的商业运营中。商业相关方凝聚共识、签署协议、分层激励，这一举措极大提高了相关方的热情与效率。华盛DAC项目实施3个月以来，业务额突破3000万元。
      </div>
      <div class="content-3"></div>
    </div>
    <div class="right">
      <div class="content-1">
        <div class="text">项目流程</div>
      </div>
      <ProjectProcess></ProjectProcess>
    </div>
  </div>
</template>

<script>
import ProjectProcess from "@/views/Dac/com/Cartoon/ProjectProcess";

export default {
  components:{
    ProjectProcess
  }
}
</script>

<style scoped lang="scss">
.content_9 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 1000px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  margin-top: 60px;

  .left {
    position: absolute;
    left: 0;
    width: 280px;
    height: 100%;
    top: 0;
    color: #fff;

    .content-1 {
      font-size: 20px;
    }

    .content-2 {
      font-size: 16px;
      margin-top: 50px;
      line-height: 35px;
    }

    .bg {
      position: absolute;
      width: 21px;
      height: 124px;
      background: linear-gradient(180deg, #7B5FD7 0%, #3F75E4 100%);
      left: -50px;
      top: 0;
    }
    .content-3{
      width: 280px;
      height: 447px;
      position: absolute;
      left: 0;
      top: 400px;
      background-size: 100% 100%;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Dac/content-9-2.png");
    }
  }

  .right {
    width: 850px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    .content-1 {
      width: 280px;
      height: 58px;
      background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
      border-radius: 25px;
      position: relative;
      margin: 0 auto;
      margin-top: 40px;

      .text {
        background-color: #000;
        width: 275px;
        height: 54px;
        position: absolute;
        left: 2.5px;
        top: 2px;
        border-radius: 25px;
        color: #fff;
        text-align: center;
        font-size: 18px;
        line-height: 55px;
      }
    }
  }
}
</style>