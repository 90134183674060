<template>
  <div class="content_5">
    <div class="left">
      <p>去中⼼化⾃治共同体的成员共分为四层结构：</p>
      <p>核⼼部分是负责整个共同体运营决策的核⼼管理层，体现为法⼈公司的合伙⼈或股东。</p>
      <p>
        其次是组织成员，与公司签订保密和劳动合同是组织成员的基本判断标准，类似于传统法⼈公司中的员⼯⼀⻆，但并不限定其职级、地位及是否全职。</p>
      <p>第三层为社区⽤户，⽤户对平台和组织的贡献有机会转化成具有价值的经济通证。</p>
      <p>最外部为外部利益相关⽅，包含政府、产业链上下游⼚商、经销商、供应商等。</p>
    </div>
    <CompanyText></CompanyText>
    <Economic class="bg"></Economic>
    <div class="content-1"></div>
    <div class="content-2">
      <p>3QLAB股权凭证+加密货币实物</p>
      <p class="title">蜗牛币</p>
    </div>
  </div>
</template>

<script>
import Economic from "@/views/Dac/com/Cartoon/Economic";
import CompanyText from "@/views/Dac/com/Cartoon/CompanyText";

export default {
  components: {
    Economic,
    CompanyText
  }
}
</script>

<style scoped lang="scss">
.content_5 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 650px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;

  .bg {
    width: 459px;
    height: 459px;
    position: absolute;
    left: 400px;
    top: 0;
    //background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Dac/content-5.png");
    //background-size: 100% 100%;
  }

  .left {
    width: 286px;
    height: 427px;
    position: absolute;
    left: 0;
    top: 0;

    p {
      color: #fff;
      font-size: 13px;
      line-height: 25px;
      margin-bottom: 15px;
    }
  }


  .content-1 {
    width: 212px;
    height: 107px;
    background-size: 100% 100%;
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Dac/content-5-1.png");
    position: absolute;
    left: 0;
    top: 500px;
  }

  .content-2 {
    width: 189px;
    height: 80px;
    color: #A3A3A3;
    font-size: 13px;
    position: absolute;
    left: 240px;
    top: 500px;

    .title {
      color: #fff;
      font-size: 20px;
    }
  }
}
</style>