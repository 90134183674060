<template>
  <div>
    <div class="block" v-bind:class="{open:link_1}">
      <div class="number">1</div>
      <div class="text">类似积分的贡献值将作为一种贡献凭证，存储到区块链上无法篡改。</div>
    </div>
    <div class="block" v-bind:class="{open:link_2}">
      <div class="number">2</div>
      <div class="text">贡献值对应奖金，通过智能合约自动发放，满足条件后将会自动打到相应的账户。</div>
    </div>
    <div class="block" v-bind:class="{open:link_3}">
      <div class="number">3</div>
      <div class="text">
        打通贡献值与股权之间的关联，当社区用户和相关方获得了较高的贡献值时，证明其对组织贡献巨大，经过本人及核心管理层决策同意，贡献值可转化为股权。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
      link_2: false,
      link_3: false,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle(){
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 2) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 200)
      setTimeout(() => {
        this.link_2 = true
      }, 800)
      setTimeout(() => {
        this.link_3 = true
      }, 1400)
    }
  }
}
</script>

<style scoped lang="scss">
.block {
  position: relative;
  width: 700px;
  height: 40px;
  margin-bottom: 40px;
  opacity: 0;
  transition: 0.3s all;

  .number {
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    position: absolute;
    left: 0;
    top: -10px;
    font-size: 30px;
  }

  .text {
    width: 646px;
    height: 40px;
    color: #fff;
    position: absolute;
    left: 60px;
  }
}


.open{
  opacity: 1;
}
</style>