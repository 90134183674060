<template>
<div class="content_7">
  <div class="bg"></div>
  <div class="bg-2">
    <div class="navTitle">
      总贡献值
    </div>
    <div class="nav-link"></div>
    <div class="nav">
      <div class="block" v-for="(item,index) in data" :key="index">
        <p class="title">{{item.title}}</p>
        <p>{{item.data}}</p>
      </div>
    </div>
  </div>
  <div class="content-1">CVS，CONTRIBUTION VALUE SYSTEMTUV</div>
  <div class="content-2">
    技术基础：活动贡献值系统
    <div class="right-hr"></div>
    <div class="qr">
      点击进入贡献值系统
      <div class="qrImage">
        <img src="https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-2-9/1_667619374_171_85_3_665775898_4019fb32cfc43424828852badbd245cd.png">
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { fetchToNumber} from "@/utils/general";

export default {
  data(){
    return{
      data:[]
    }
  },
  methods:{
    initData: async () => {
      let url = "http://admin.contribution.3qlab.com/api/Admin/Website/find/TolNumber";
      let res = await fetchToNumber(url);
      return res;
    },
    async init(){
      let sum=await this.initData()
      this.data=sum
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.content_7{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 800px;
  margin: 0 auto;
  position: relative;
  margin-top: 50px;

  .bg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-2-9/20230221213936.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90% 100%;
  }

  .bg-2{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background:  linear-gradient(180deg,#000000 0%,rgba(0,0,0,0.64) 100%);
    .navTitle{
      width: 50px;
      height: 200px;
      writing-mode: tb-rl;
      letter-spacing: 30px;
      color: #fff;
    }
    .nav-link{
      width: 400px;
      height: 2px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      left: -160px;
      top: 400px;
      transform: rotate(90deg);
    }
    .nav{
      width: 1000px;
      height: 50px;
      position: absolute;
      left: 100px;
      top: 0;
      line-height: 8px;
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
      font-size: 12px;
      .block{
        text-align: center;
        width: 125px;
        height: 100%;
        .title{
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }

  .content-1 {
    color: #A3A3A3;
    font-size: 20px;
    position: absolute;
    left: 0;
    bottom: 50px;
  }

  .content-2 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    .right-hr {
      width: 650px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 170px;
      top: 17px;
    }

    .qr {
      color: #000;
      width: 142px;
      height: 40px;
      background-color: #fff;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: -5px;
      font-weight: bold;
      cursor: pointer;
      user-select: none;

      .qrImage {
        width: 100px;
        height: 100px;
        background-color: #fff;
        position: absolute;
        top: -110px;
        left: 20px;
        opacity: 0;
        transition: 0.3s all;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .qr:hover .qrImage {
      opacity: 1;
    }
  }



}
</style>