<template>
  <div class="content-2">
    <div class="block">
      <div class="number">1</div>
      <div class="text-1" v-bind:class="{open:text_1}">项目发起</div>
      <div class="line-1" v-bind:class="{open:text_2}"></div>
      <div class="text" v-bind:class="{open:text_3}">项目发起申请:需要确定项目贡献值总额、相应等额现金存入、确定委员会成员</div>
      <div class="line-1" v-bind:class="{open:text_4}"></div>
      <div class="text" v-bind:class="{open:text_5}">项目审核无误后<br>通过</div>
      <div class="line-1" v-bind:class="{open:text_6}"></div>
      <div class="text" v-bind:class="{open:text_7}">审核通过后发起签署</div>
      <div class="line-1" v-bind:class="{open:text_8}"></div>
      <div class="text" v-bind:class="{open:text_9}">委员会成员确认无误后<br>签署完成</div>
      <div class="line-1"  v-bind:class="{open:text_10}"></div>
      <div class="text-1" v-bind:class="{open:text_11}">发起成功</div>
    </div>
    <div class="block">
      <div class="number">2</div>
      <div class="text-1" v-bind:class="{open:text_1}">贡献值管理</div>
      <div class="line-1" v-bind:class="{open:text_2}"></div>
      <div class="text" v-bind:class="{open:text_3}">贡献值批量分配:选择所在项目,根据文件格式填写excel表格,填写活动昵称(标签)</div>
      <div class="line-1" v-bind:class="{open:text_4}"></div>
      <div class="text" v-bind:class="{open:text_5}">项目审核无误后<br>通过</div>
      <div class="line-1" v-bind:class="{open:text_6}"> </div>
      <div class="text" v-bind:class="{open:text_7}">贡献码储值发放确认:填写项目发放负责人委员会机构主题、抄送方(可选填)生成签署文档,发起签署</div>
      <div class="line-1" v-bind:class="{open:text_8}"></div>
      <div class="text" v-bind:class="{open:text_9}">委员会成员确认无误后<br>签署完成</div>
    </div>
    <div class="block">
      <div class="number">3</div>
      <div class="text-1" v-bind:class="{open:text_1}">奖金确认管理</div>
      <div class="line-1" v-bind:class="{open:text_2}"></div>
      <div class="text" v-bind:class="{open:text_3}">奖金发放发起:选择财务人员,选择奖励发放方式,完成成签署文档,发起签署</div>
      <div class="line-1" v-bind:class="{open:text_4}"></div>
      <div class="text" v-bind:class="{open:text_5}">财务人员审核无误后<br>签署完成</div>
      <div class="line-1" v-bind:class="{open:text_6}"></div>
      <div class="text" v-bind:class="{open:text_7}">奖金发放管理: <br>填写相关证明资料</div>
      <div class="line-1" v-bind:class="{open:text_8}"></div>
      <div class="text" v-bind:class="{open:text_9}">确认归档<br>签署完成</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text_1: false,
      text_2: false,
      text_3: false,
      text_4: false,
      text_5: false,
      text_6:false,
      text_7:false,
      text_8:false,
      text_9:false,
      text_10:false,
      text_11:false,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle(){
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 2) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.text_1 = true
      }, 300)
      setTimeout(() => {
        this.text_2 = true
      }, 800)
      setTimeout(() => {
        this.text_3 = true
      }, 1300)
      setTimeout(() => {
        this.text_4 = true
      }, 1800)
      setTimeout(() => {
        this.text_5 = true
      }, 2300)
      setTimeout(() => {
        this.text_6 = true
      }, 2800)
      setTimeout(() => {
        this.text_7 = true
      }, 3300)
      setTimeout(() => {
        this.text_8 = true
      }, 3800)
      setTimeout(() => {
        this.text_9 = true
      }, 4300)
      setTimeout(() => {
        this.text_10 = true
      }, 4800)
      setTimeout(() => {
        this.text_11 = true
      }, 5300)
    }
  }
}
</script>

<style scoped lang="scss">
.content-2 {
  width: 100%;
  height: 600px;
  margin-top: 60px;
  display: flex;
  justify-content: space-around;

  .block {
    width: 200px;
    height: 100%;

    .number {
      border: 1px solid #7C5FD7;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      color: #7C5FD7;
      text-align: center;
      line-height: 40px;
      font-size: 30px;
    }

    .text-1 {
      margin-top: 10px;
      color: #7C5FD7;
      font-size: 25px;
      transition: 0.3s all;
      opacity: 0;
    }

    .line-1 {
      width: 27px;
      height: 17px;
      background-size: 100% 100%;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Dac/contnent-9-1.png");
      margin-top: 50px;
      transition: 0.3s all;
      opacity: 0;
    }

    .text {
      color: #fff;
      font-size: 13px;
      margin-top: 20px;
      line-height: 28px;
      transition: 0.3s all;
      opacity: 0;
    }

    .open {
      opacity: 1;
    }
  }
}
</style>
