<template>
<div class="content_6">
  <div class="content-1">
    <div class="text">去中心化自治共同体经济通证</div>
  </div>
  <div class="content-2">
    <table>
      <colgroup span="4"></colgroup>
      <tr>
        <th class="th-1"></th>
        <th class="th-1">经济通行</th>
        <th class="th-1">发放依据</th>
      </tr>
      <tr>
        <td class="">核心管理层</td>
        <td class="td-2"><div class="lint"></div>影响并参与重大决策</td>
        <td class="td-2"><div class="lint"></div>股权激励</td>
      </tr>
      <tr>
        <td class="">组织成员</td>
        <td class="td-2"><div class="lint"></div>薪酬奖励</td>
        <td class="td-2"><div class="lint"></div>签订合同的常态化激励</td>
      </tr>
      <tr>
        <td class="">社区用户<br>&<br>外部利益相关方</td>
        <td class="td-3"><div class="lint"></div>社区贡献值</td>
        <td class="td-3"><div class="lint"></div>为DAC公司法人带来价值</td>
      </tr>
    </table>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.content_6{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 600px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  .content-1{
    width: 280px;
    height: 58px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    border-radius: 25px;
    position: relative;
    margin: 0 auto;
    margin-top: 40px;

    .text {
      background-color: #000;
      width: 275px;
      height: 54px;
      position: absolute;
      left: 2.5px;
      top: 2px;
      border-radius: 25px;
      color: #fff;
      text-align: center;
      font-size: 18px;
      line-height: 55px;
    }
  }
  .content-2{
    width: 100%;
    height: 450px;
    //background-color: #fff;
    margin-top: 60px;
    color: #fff;
    table,th,td{
      border: 1px solid black;
    }
    th,td{
      height: 80px;
      text-align: center;
      border-bottom: 1px solid #8e8e8e;
    }
    table{
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
    }
    .th-1{
      border-top: 1px solid #fff;
    }
    .td-2{
      color: #7C5FD7;
      position: relative;
      .lint{
        width: 1px;
        height: 30px;
        background-color: #A3A3A3;
        position: absolute;
        left: 0;
      }
    }
    .td-3{
      height: 190px;
      line-height: 50px;
      color: #7C5FD7;
      position: relative;
      .lint{
        width: 1px;
        height: 30px;
        background-color: #A3A3A3;
        position: absolute;
        left: 0;
      }
    }
  }
}
</style>