<template>
  <div class="content_8">
    <div class="content-1">
      <p>贡献值系统是指以贡献值为基础的社区共识机制与激励系统。</p>
      <p>贡献值包含了三个方面的利益和价值：</p>
    </div>
    <div class="content-2">
      <div class="bg"></div>
      <div class="right">
        <Integral></Integral>
      </div>
    </div>
  </div>
</template>

<script>
import Integral from "@/views/Dac/com/Cartoon/Integral";

export default {
  components:{
    Integral
  }
}
</script>

<style scoped lang="scss">
.content_8 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 350px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  margin-top: 50px;

  .content-1 {
    color: #fff;
    font-size: 13px;
  }
  .content-2{
    width: 100%;
    height: 250px;
    margin-top: 30px;
    position: relative;
    .bg{
      width: 79px;
      height: 79px;
      background-size: 100% 100%;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Dac/content-8-1.png");
      position: absolute;
      top: 80px;
      left: 100px;
    }
    .right{
      position: absolute;
      right: 0;
      top: 30px;
      width: 850px;
      height: 100%;
    }
  }
}
</style>