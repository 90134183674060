<template>
  <div class="content_1">
    <div class="main">
      <div class="title-1"></div>
      <div class="title-2">3QDAC
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="text">
       <p>DAC（Decentralized Autonomous Community，去中心化⾃治共同体）是3QLAB基于DAO模式
         （去中心化⾃治组织，Decentralized Autonomous Organization）构建的⼀种多方协作的新型系统
         ——为了完成某个使命和愿景，每个独⽴的组织单元、人、机器之间通过区块链技术进行贡献记
         录、激励分配、协作分工，最终达成目标。</p>
       <p>真正做到去中心化自治、动态化链接和分布式存储有机结合，从而更好地激发组织的效能和实现价
         值流转。</p>
        <p>目前DAC已被运⽤到3QLAB的技术、商业实践中，也期待更多有志之士加入，与我们同行。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.content_1 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 650px;
  margin: 0 auto;
  background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Dac/content-1.png");
  background-size: 100% 100%;
  position: relative;

  .main {
    width: 500px;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 50%;
    margin-left: -250px;

    .title-1 {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 16px;
      position: absolute;
      top: 40px;
    }

    .title-2 {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      position: absolute;
      top: 80px;
      @mixin hr{
        width: 120px;
        height: 1px;
        position: absolute;
        border: 0;
        padding-top: 1px;
      }
      .left{
        @include hr;
        background: linear-gradient(to right, transparent, #d0d0d5);
        left: 40px;
        top: 20px;
      }
      .right {
        @include hr;
        background: linear-gradient(to left, transparent, #d0d0d5);
        right: 40px;
        top: 20px;
      }
    }
    .text{
      width: 380px;
      height: 200px;
      color: #fff;
      position: absolute;
      left: 55px;
      top: 150px;
      letter-spacing:3px;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
    }
  }
}
</style>