<template>
  <div class="content_2">
    <div class="block">
      <div class="number">1</div>
      <div class="text">
        <div class="text-bg">制定分配方案</div>
      </div>
    </div>
    <div class="block">
      <div class="number">2</div>
      <div class="text">
        <div class="text-bg">签署共识协议</div>
      </div>
    </div>
    <div class="block">
      <div class="number">3</div>
      <div class="text">
        <div class="text-bg">认领完成项目</div>
      </div>
    </div>
    <div class="block">
      <div class="number">4</div>
      <div class="text">
        <div class="text-bg">结算分配激励</div>
      </div>
    </div>
    <div class="line-1"></div>
    <div class="line-2"></div>
    <div class="line-3"></div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.content_2 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 250px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-top: 100px;

  .block {
    width: 150px;
    height: 180px;

    .number {
      width: 29px;
      height: 58px;
      font-size: 47px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 53px;
      background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0 auto;
    }

    .text {
      width: 150px;
      height: 58px;
      background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
      border-radius: 25px;
      position: relative;
      margin-top: 50px;

      .text-bg {
        background-color: #000;
        width: 145px;
        height: 54px;
        position: absolute;
        left: 2.5px;
        top: 2px;
        border-radius: 25px;
        color: #fff;
        text-align: center;
        font-size: 18px;
        line-height: 55px;
      }
    }
  }

  @mixin link {
    width: 153px;
    height: 1px;
    border: 1px solid;
    border-image: linear-gradient(63deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) 1 1;
    position: absolute;
    top: 25px;
  }

  .line-1 {
    @include link;
    left: 200px;

  }

  .line-2 {
    @include link;
    left: 500px;
  }

  .line-3{
    @include link;
    left: 800px;
  }
}
</style>