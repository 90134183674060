<template>
  <div class="right">
    <div class="block-1" v-bind:class="{'open':link_1}">
      法人公司的合伙人或股东
      <div class="hr"></div>
    </div>
    <div class="block-2" v-bind:class="{'open':link_2}">
      公司签订保密和劳动合同是组织成员,不限定其职级、地位及是否全职
      <div class="hr"></div>
    </div>
    <div class="block-3" v-bind:class="{'open':link_3}">
      政府、产业链上下游厂商、经销商、供应商等
      <div class="hr"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
      link_2: false,
      link_3: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle(){
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 200)
      setTimeout(() => {
        this.link_2 = true
      }, 1400)
      setTimeout(() => {
        this.link_3 = true
      }, 2000)
    }
  }
}
</script>

<style scoped lang="scss">
.right {
  position: absolute;
  width: 228px;
  height: 459px;
  right: 0;
  top: 0;

  @mixin block {
    z-index: 10;
    position: absolute;
    color: #A3A3A3;
    font-size: 20px;
    left: 0;
    width: 228px;
    transition: 0.3s;
    opacity: 0;
    .hr {
      position: absolute;
      width: 250px;
      height: 1px;
      left: -260px;
      top: 16px;
      background-color: #A3A3A3;
    }
  }

  .block-1 {
    @include block;
    top: 26px;
  }

  .block-2 {
    @include block;
    top: 113px;
  }

  .block-3 {
    @include block;
    top: 290px;
  }

  .open{
    opacity: 1;
  }
}
</style>