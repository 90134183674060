<template>
  <div class="content_3">
    全程线上，区块链存证，有迹可循，具备法律效力
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.content_3 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 85px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  text-align: center;
  border: 1px solid;
  border-image: linear-gradient(90deg, rgba(62, 117, 228, 1), rgba(124, 95, 215, 1)) 1 1;
  border-left: none;
  border-right: none;
  line-height: 85px;
  color: #fff;
  font-size: 25px;
}
</style>