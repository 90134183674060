<template>
  <div class="Economics">
    <div class="round-4" v-bind:class="{'round-open':round_4}">外部利益相关方</div>
    <div class="round-3" v-bind:class="{'round-open':round_3}">社区用户</div>
    <div class="round-2" v-bind:class="{'round-open':round_2}">组织成员</div>
    <div class="round-1" v-bind:class="{'round-open':round_1}">核心管理层</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      round_1: false,
      round_2: false,
      round_3: false,
      round_4: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle(){
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.round_1 = true
      }, 200)
      setTimeout(() => {
        this.round_2 = true
      }, 800)
      setTimeout(() => {
        this.round_3 = true
      }, 1400)
      setTimeout(() => {
        this.round_4 = true
      }, 2000)
    }
  }

}
</script>

<style scoped lang="scss">


img {
  width: 350px;
}

.Economics {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 350px;
  height: 350px;
  position: relative;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  user-select: none;

  .round-1 {
    width: 100px;
    height: 100px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -50px;
    border-radius: 50px;
    box-shadow: 0 1px 5px 3px #5156a5;
    line-height: 100px;
    transition: 0.3s all;
    opacity: 0;
  }

  .round-2 {
    width: 180px;
    height: 180px;
    background: linear-gradient(180deg,  #6e79e1 20%, #7863d9 70%);
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -90px;
    border-radius: 90px;
    box-shadow: 0 1px 5px 3px #5156a5;
    line-height: 280px;
    transition: 0.3s all;
    opacity: 0;
  }

  .round-3 {
    width: 260px;
    height: 260px;
    background: linear-gradient(180deg,  #6e79e1 30%, #7863d9 70%);
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -130px;
    border-radius: 130px;
    box-shadow: 0 1px 5px 3px #5156a5;
    line-height: 450px;
    transition: 0.3s all;
    opacity: 0;
  }

  .round-4 {
    width: 350px;
    height: 350px;
    background: linear-gradient(180deg,  #6e79e1 50%, #7863d9 100%);
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -175px;
    border-radius: 175px;
    box-shadow: 0 1px 5px 3px #5156a5;
    line-height: 610px;
    transition: 0.3s all;
    opacity: 0;
  }

  .round-open {
    opacity: 1;
  }
}
</style>