<template>
<div class="content_4">
  <div class="content-1">
    HIERARCHY AND ECONOMIC PASSES
  </div>
  <div class="content-2">
    层级结构与经济通证
  </div>
  <div class="content-3">
    <div class="text">去中心化自治共同体的成员结构</div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.content_4{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 250px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  margin-top: 50px;
  .content-1{
    font-size: 16px;
    color: #A3A3A3;
    width: 100%;
  }
  .content-2{
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: relative;
    .right-hr{
      width: 1000px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 0;
      top: 17px;
    }
  }
  .content-3{
    width: 280px;
    height: 58px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    border-radius: 25px;
    position: relative;
    margin-top: 40px;

    .text {
      background-color: #000;
      width: 275px;
      height: 54px;
      position: absolute;
      left: 2.5px;
      top: 2px;
      border-radius: 25px;
      color: #fff;
      text-align: center;
      font-size: 18px;
      line-height: 55px;
    }
  }
}
</style>