<template>
  <div class="home">
    <Header></Header>
    <content_1></content_1>
    <content_2></content_2>
    <content_3></content_3>
    <content_4></content_4>
    <content_5></content_5>
    <content_6></content_6>
    <content_7></content_7>
    <content_8></content_8>
    <content_9></content_9>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import content_1 from "@/views/Dac/com/content_1";
import content_2 from "@/views/Dac/com/content_2";
import content_3 from "@/views/Dac/com/content_3";
import content_4 from "@/views/Dac/com/content_4";
import content_5 from "@/views/Dac/com/content_5";
import content_6 from "@/views/Dac/com/content_6";
import content_7 from "@/views/Dac/com/content_7";
import content_8 from "@/views/Dac/com/content_8";
import content_9 from "@/views/Dac/com/content_9";

export default {
  data() {
    return {
      listheight: ''
    };
  },
  components: {
    Header,
    Footer,
    content_1,
    content_2,
    content_3,
    content_4,
    content_5,
    content_6,
    content_7,
    content_8,
    content_9
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let listheight = document.documentElement.clientHeight - 80
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight

    }
  }

}
</script>

<style scoped lang="scss">


.home {
  background-color: #000;
  width: 100%;
}


</style>
